import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { NavigatorProps } from '../NavigatorProps'
import { Navigator, RangeNavigator, SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { ContextLink } from '@bbx/common/types/contextLinks'
import {
    ModalNavigator,
    ModalNavigatorProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigator'
import {
    buildModelNavigatorLabel,
    groupModelNavigatorByMakes,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/model-navigator-functions'
import { WidthProps } from '@wh-components/system/layout'
import { Box } from '@wh-components/core/Box/Box'
import { sortAlphabetically } from '@wh/common/chapter/lib/commonHelpers'
import { getQueryParams } from '@wh/common/chapter/lib/urlHelpers'
import { isNotUndefined } from '@wh/common/chapter/lib/functionalHelpers'

interface GroupedModelNavigatorsProps {
    groupModels?: boolean
}

export const GroupedModelNavigators: FunctionComponent<
    NavigatorProps & GroupedModelNavigatorsProps & ModalNavigatorProps & Partial<Pick<WidthProps, 'width' | 'maxWidth'>>
> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    groupModels = true,
    showSelectedValues,
    width,
    disabled,
    maxWidth,
    selectedValueInColumns,
    taggingData,
}) => {
    const allModelNavigators = useMemo(() => {
        const groupedModelNavigators = getGroupedModelNavigators(navigator)
        return groupedModelNavigators.length > 1
            ? groupedModelNavigators.sort((a, b) => {
                  const labelA = a.modelNavigator?.groupedPossibleValues[0].possibleValues[0].parentLabel
                  const labelB = b.modelNavigator?.groupedPossibleValues[0].possibleValues[0].parentLabel
                  return sortAlphabetically(labelA, labelB)
              })
            : groupedModelNavigators
    }, [navigator])

    const moreThanOneMake = groupModels && allModelNavigators.length > 1

    /* Some greatness is happening here
       We have one model-navigator coming from the API
       Above, we group it by make and create multiple (fake) model-navigators
       When searching, we need to append all selected values from all (fake) model-navigators again
       Only for the current (fake) model-navigator, which triggered the search, we use the new received params
       For all other (fake) model-navigators, we use the old selected-values
     */
    const onModelSearch = async (makeId: string, contextLink: ContextLink, additionalParams?: Record<string, string | string[]>) => {
        const selectedValuesFromAllOtherModelNavigators = allModelNavigators
            .filter(({ makeId: innerMakeId }) => makeId !== innerMakeId)
            .map(({ modelNavigator: innerModelNavigator }) => innerModelNavigator.selectedValues)
            .reduce((a, b) => a.concat(b), [])

        const allOldAdditionalParams = buildAdditionalParamsFromSelectedItems(selectedValuesFromAllOtherModelNavigators)
        const mergedParams = mergeOldAndNewParams(allOldAdditionalParams, additionalParams)
        const exclusiveAdditionalParams = getParamsNotAlreadyIncludedInUrl(mergedParams, contextLink.relativePath ?? '')

        await onSearch(contextLink, exclusiveAdditionalParams)
    }

    return (
        <Fragment>
            {allModelNavigators.map(({ makeId, modelNavigator }) => {
                const label = moreThanOneMake
                    ? buildModelNavigatorLabel(modelNavigator?.groupedPossibleValues[0]?.possibleValues?.[0])
                    : 'Modell'
                const testIdFragment = `${modelNavigator.id}-${modelNavigator?.groupedPossibleValues[0]?.possibleValues?.[0]?.parentLabel}`
                return (
                    <Box
                        testId={`navigator-${testIdFragment}-wrapper`}
                        key={makeId}
                        paddingBottom={moreThanOneMake ? { phone: 'm', tablet: 's' } : undefined}
                    >
                        <ModalNavigator
                            navigator={modelNavigator}
                            onSearch={async (contextLink, additionalParams) => {
                                await onModelSearch(makeId, contextLink, additionalParams)
                            }}
                            aria-labelledby={ariaLabelledBy}
                            label={label}
                            modalHeader={label}
                            showSelectedValues={showSelectedValues}
                            sortSelectedValues={(a, b) => (a.label < b.label ? -1 : 1)}
                            width={width}
                            maxWidth={maxWidth}
                            disabled={disabled}
                            selectedValueInColumns={selectedValueInColumns}
                            testIdFragmentOverride={testIdFragment}
                            taggingData={taggingData}
                        />
                    </Box>
                )
            })}
        </Fragment>
    )
}

export const getGroupedModelNavigators = (navigator: Navigator | RangeNavigator) => {
    const groupedModelValues = groupModelNavigatorByMakes(navigator)

    return Object.entries(groupedModelValues).map(([makeId, values]) => {
        const copiedNavigator: Navigator = JSON.parse(JSON.stringify(navigator)) as Navigator
        copiedNavigator.groupedPossibleValues = [{ possibleValues: values.map((value) => value.possibleValue) }]
        copiedNavigator.selectedValues = values.map((value) => value.selectedValue).filter(isNotUndefined)
        return { modelNavigator: copiedNavigator, makeId: makeId }
    })
}

const buildAdditionalParamsFromSelectedItems = (selectedValues: SelectedNavigatorValue[]) => {
    if (selectedValues.length === 0) {
        return {}
    }

    const urlParamName = selectedValues[0].urlParamRepresentationForValue[0].urlParameterName

    const values = selectedValues.map((selectedValue) => selectedValue.urlParamRepresentationForValue[0].value)

    return { [urlParamName]: values }
}

export const getParamsNotAlreadyIncludedInUrl = (params: Record<string, string | string[]>, url: string) => {
    const queryParams = getQueryParams(url)

    return Object.keys(params).reduce((acc: Record<string, string[]>, key) => {
        const value = params[key]
        const valueArray = typeof value === 'string' ? [value] : value
        const exclusiveValues = valueArray.filter((paramValue) => !queryParams[key]?.includes(paramValue))
        return exclusiveValues?.length > 0 ? { ...acc, [key]: exclusiveValues } : acc
    }, {})
}

export const mergeOldAndNewParams = (
    allOldAdditionalParams: Record<string, string | string[]>,
    additionalParams?: Record<string, string | string[]>,
) => {
    const result: Record<string, string[]> = {}

    Object.entries(allOldAdditionalParams).forEach(([key, value]) => {
        const valueArray = typeof value === 'string' ? [value] : value
        if (result[key]) {
            result[key] = result[key].concat(valueArray)
        } else {
            result[key] = valueArray
        }
    })

    if (additionalParams) {
        Object.entries(additionalParams).forEach(([key, value]) => {
            const valueArray = typeof value === 'string' ? [value] : value
            if (result[key]) {
                result[key] = result[key].concat(valueArray)
            } else {
                result[key] = valueArray
            }
        })
    }

    return result
}
