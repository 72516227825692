import { Navigator, PossibleNavigatorValue, RangeNavigator, SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { getFlatPossibleValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export type GroupedNavigatorValues = Record<string, NavigatorValuePair[]>
export type NavigatorValuePair = { possibleValue: PossibleNavigatorValue; selectedValue: SelectedNavigatorValue }

export const groupModelNavigatorByMakes = (navigator: Navigator | RangeNavigator) => {
    return getFlatPossibleValues(navigator).reduce((result: GroupedNavigatorValues, currentPossibleValue: PossibleNavigatorValue) => {
        const selectedValue = getSelectedValueFromPossibleValues(navigator, currentPossibleValue.urlParamRepresentationForValue[0].value)
        ;(result[currentPossibleValue.parentId!] = result[currentPossibleValue.parentId!] || []).push({
            possibleValue: currentPossibleValue,
            selectedValue: selectedValue,
        })

        return result
    }, {})
}

const getSelectedValueFromPossibleValues = (navigator: Navigator, possibleValue: string) => {
    return navigator.selectedValues.filter((selectedValue) => selectedValue.urlParamRepresentationForValue[0].value === possibleValue)[0]
}

export const buildModelNavigatorLabel = (possibleValue?: PossibleNavigatorValue) =>
    possibleValue ? `${possibleValue.parentLabel}-Modelle` : 'Modell'
