import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Radio } from '@wh-components/core/FormElements/Radio/Radio'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { calculateKwValueRaw, calculatePsValueRaw } from '@bbx/common/lib/CalculatePsValue'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { css } from 'styled-components'
import { ENGINE_EFFECT_NAVIGATOR_UNIT } from '@wh/common/chapter/lib/localStorage'
import { isRangeNavigatorAvailable } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { RangeNavigatorComponent, RangeNavigatorProps } from './RangeNavigatorComponent'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'

export type EngineEffectUnit = 'PS' | 'KW'
const defaultEngineEffectUnit = 'PS'

const copyNavigator = function (navigator: Navigator | RangeNavigator, selectedUnit: 'PS' | 'KW') {
    const copiedNavigator = JSON.parse(JSON.stringify(navigator)) as Navigator

    if (selectedUnit === 'PS') {
        copiedNavigator.selectedValues.forEach((value) => {
            value.urlParamRepresentationForValue.forEach((value2) => {
                value2.value = calculatePsValueRaw(value2.value)!
            })
        })
    }
    return copiedNavigator
}

export const EngineEffectNavigator: FunctionComponent<RangeNavigatorProps> = ({
    navigator,
    onSearch,
    abortRequest,
    disabled = false,
    taggingData,
}) => {
    const [selectedUnit, setSelectedUnit] = useState<EngineEffectUnit>(defaultEngineEffectUnit)
    const copiedNavigator = useMemo(() => copyNavigator(navigator, selectedUnit), [navigator, selectedUnit])

    const setSelectedUnitWithLocalstorage = (selectedUnitInner: EngineEffectUnit) => {
        setSelectedUnit(selectedUnitInner)
        if (storageAvailable('localStorage')) {
            localStorage.setItem(ENGINE_EFFECT_NAVIGATOR_UNIT, selectedUnitInner)
        }
    }

    const onEngineEffectSearch = async (searchLink: ContextLink, additionalParams?: Record<string, string | string[]>) => {
        const newAdditionalParams = additionalParams && selectedUnit === 'PS' ? convertPsParamsToKw(additionalParams) : additionalParams

        return onSearch(searchLink, newAdditionalParams)
    }

    useEffect(() => {
        const selectedUnitInLocalstorage: EngineEffectUnit = storageAvailable('localStorage')
            ? ((localStorage.getItem(ENGINE_EFFECT_NAVIGATOR_UNIT) ?? defaultEngineEffectUnit) as EngineEffectUnit)
            : defaultEngineEffectUnit

        setSelectedUnit(selectedUnitInLocalstorage)
    }, [])

    if (!isRangeNavigatorAvailable(copiedNavigator)) {
        return null
    }

    return (
        <Box>
            <RangeNavigatorComponent
                navigator={copiedNavigator}
                onSearch={onEngineEffectSearch}
                abortRequest={abortRequest}
                enableAlternativeRange={selectedUnit !== 'KW'}
                label="Leistung"
                disabled={disabled}
                taggingData={taggingData}
            />
            <Box display="flex">
                <Radio
                    id="engine-effect-navigator-radio-ps"
                    testId="engine-effect-navigator-radio-ps"
                    label="PS"
                    value="PS"
                    name="engine-effect-navigator"
                    checked={selectedUnit === 'PS'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value === 'PS') {
                            setSelectedUnitWithLocalstorage('PS')
                        }
                    }}
                    css={css`
                        padding-right: ${(p) => p.theme.space.m}px;
                    `}
                />
                <Radio
                    id="engine-effect-navigator-radio-kw"
                    testId="engine-effect-navigator-radio-kw"
                    label="kW"
                    value="KW"
                    name="engine-effect-navigator"
                    checked={selectedUnit === 'KW'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value === 'KW') {
                            setSelectedUnitWithLocalstorage('KW')
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

const convertPsParamsToKw = (additionalParams: Record<string, string | string[]>) => {
    const newAdditionalParams: Record<string, string | string[]> = {}

    Object.keys(additionalParams).forEach((key) => {
        const param = additionalParams[key]
        // using the first value here is safe because there can be only one value for range-navigators
        const firstValue = typeof param === 'string' ? param : param[0]
        newAdditionalParams[key] = calculateKwValueRaw(firstValue)!
    })

    return newAdditionalParams
}
