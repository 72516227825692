import React, { FunctionComponent } from 'react'
import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Box } from '@wh-components/core/Box/Box'
import CheckMarkIcon from '@wh-components/icons/CheckThick'
import { useMultiSelectCheckboxState } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useMultiSelectCheckboxState'
import {
    MultiSelectNavigatorLayout,
    NavigatorItemLayoutProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MultiSelectNavigatorLayout'

export const AutoColorNavigator: FunctionComponent<NavigatorProps & NavigatorItemLayoutProps> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    tabletItemWidth = '50%',
    taggingData,
}) => {
    const { checkboxState, handleChange } = useMultiSelectCheckboxState(navigator)

    const getCheckboxContent = (checked: boolean) => (possibleValue: PossibleNavigatorValue) => {
        const colorId = possibleValue.urlParamRepresentationForValue[0]?.value ?? ''
        const colorName = possibleValue.label
        return <ColorCheckboxContent colorId={colorId} colorName={colorName} checked={checked} />
    }

    return (
        <MultiSelectNavigatorLayout
            checkboxState={checkboxState}
            navigator={navigator}
            onChange={handleChange}
            onSearch={onSearch}
            taggingData={taggingData}
            tabletItemWidth={tabletItemWidth}
            aria-labelledby={ariaLabelledBy}
            checkedContent={getCheckboxContent(true)}
            uncheckedContent={getCheckboxContent(false)}
            checkboxSize="large"
            sortValues={sortSonstigesLast}
            phoneItemWidth="50%"
        />
    )
}

interface ColorCheckboxContentProps {
    colorId: string
    colorName: string
    checked: boolean
}

const fallbackCheckboxColor: CheckboxColor = { type: 'plain', hexColor: '#f9fafd', checkmarkColor: 'black' }

const ColorCheckboxContent: FunctionComponent<ColorCheckboxContentProps> = (props) => {
    const checkboxColor = colorIdMap[props.colorId] ?? fallbackCheckboxColor
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={checkboxColor.type === 'plain' ? checkboxColor.hexColor : undefined}
            backgroundImage={
                checkboxColor.type === 'gradient'
                    ? `linear-gradient(135deg, ${checkboxColor.hexColorGradientStart}, ${checkboxColor.hexColorGradientEnd})`
                    : undefined
            }
            width="calc(100% - 2*3px)"
            height="calc(100% - 2*3px)"
            margin="3px"
            borderRadius="2px"
        >
            {props.checked && (
                <CheckMarkIcon
                    display="block"
                    size="calc(100% - 2px)"
                    color={checkboxColor.checkmarkColor === 'white' ? 'white' : 'black'}
                />
            )}
        </Box>
    )
}

type ColorId = string
type CheckboxColor =
    | {
          type: 'plain'
          hexColor: HexColor
          checkmarkColor: CheckmarkColor
      }
    | {
          type: 'gradient'
          hexColorGradientStart: HexColor
          hexColorGradientEnd: HexColor
          checkmarkColor: CheckmarkColor
      }
type HexColor = string
type CheckmarkColor = 'white' | 'black'

const colorIdMap: Record<ColorId, CheckboxColor | undefined> = {
    '1': { type: 'plain', hexColor: '#f3dfba', checkmarkColor: 'black' },
    '2': { type: 'plain', hexColor: '#0083c3', checkmarkColor: 'white' },
    '4': { type: 'plain', hexColor: '#5e4532', checkmarkColor: 'white' },
    '3': { type: 'gradient', hexColorGradientStart: '#da9c61', hexColorGradientEnd: '#795630', checkmarkColor: 'white' },
    '7': { type: 'plain', hexColor: '#ffea00', checkmarkColor: 'black' },
    '8': { type: 'gradient', hexColorGradientStart: '#fded88', hexColorGradientEnd: '#cda93d', checkmarkColor: 'black' },
    '6': { type: 'plain', hexColor: '#bababa', checkmarkColor: 'white' },
    '5': { type: 'plain', hexColor: '#009100', checkmarkColor: 'white' },
    '11': { type: 'plain', hexColor: '#e89319', checkmarkColor: 'white' },
    '13': { type: 'plain', hexColor: '#c3111f', checkmarkColor: 'white' },
    '14': { type: 'gradient', hexColorGradientStart: '#4e4e4e', hexColorGradientEnd: '#000000', checkmarkColor: 'white' },
    '15': { type: 'gradient', hexColorGradientStart: '#e6e6e6', hexColorGradientEnd: '#c2c2c2', checkmarkColor: 'black' },
    '17': { type: 'plain', hexColor: '#f9fafd', checkmarkColor: 'black' },
    '10': { type: 'plain', hexColor: '#6c005e', checkmarkColor: 'white' },
    '9': { type: 'plain', hexColor: '#ffffff', checkmarkColor: 'black' },
}

const SONSTIGES_URL_PARAM_VALUE = '17'

const sortSonstigesLast = (a: PossibleNavigatorValue, b: PossibleNavigatorValue): number =>
    navigatorValueForSortSonstigesLast(a) - navigatorValueForSortSonstigesLast(b)

const navigatorValueForSortSonstigesLast = (navigatorValue: PossibleNavigatorValue) => {
    const value: string | undefined = navigatorValue.urlParamRepresentationForValue[0]?.value
    if (value === SONSTIGES_URL_PARAM_VALUE) {
        return 1
    } else {
        return 0
    }
}
